<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-10">
        <img src="/media/logos/logo.svg" class="w-75 bg-white mb-10" />
        <h3 class="font-size-h1">Giriş Yap</h3>
        <p class="text-muted font-weight-semi-bold">
          Kullanıcı adınızı ve şifrenizi girin
        </p>
      </div>

      <!--begin::Form-->
      <b-form
        class="form"
        @submit.stop.prevent="onSubmit"
        style="min-width: 400px"
      >
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label="Email"
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Lütfen geçerli bir eposta adresi girin.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Şifre"
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Şifre alanı gereklidir.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-end align-items-center"
        >
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Giriş Yap
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { LOGIN, LOGOUT, SET_ERROR } from '@/store/auth.module';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: '',
        password: ''
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  created() {
    this.setError(null);
  },
  methods: {
    ...mapMutations([SET_ERROR]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      this.$store
        .dispatch(LOGIN, { email, password, path: 'admin' })
        // go to which page after successfully login
        .then(() => this.$router.push('/admin/schools'));

      submitButton.classList.remove(
        'spinner',
        'spinner-light',
        'spinner-right'
      );
    }
  },
  computed: {
    ...mapGetters(['errors'])
  }
};
</script>
